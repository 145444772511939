.product-container {
    /* margin: 10 200; */
    margin-top: 1rem;
    border: 1px solid rgb(205, 10, 0);
}

.product-heading {
    display: flex;
    justify-content: center;
    /* color: red; */
}

.product-bullet-marks {
    padding: 1rem;
    font-size: 25px;
    display: flex;
    justify-content: space-around;
}

.product_list_container{
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    justify-content: space-between;
    align-items: center;
}

.kemppiProductImage{
    border: 1px solid rgba(7, 7, 162, 0.26);
}

.clickableButton{
    text-align: center;
    font-size: large;
    cursor: pointer;
    width: 100% !important;
    background: rgb(7, 7, 162);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0 0 0.7rem 0.7rem;
}
.product_detals_container{
    display: flex;
    flex-direction: column;
}
.product_detals_container img{
    width: 300px;
    height: auto;
}
.product_detals_container a{
    width: 25rem;
}

/* View Details */
/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 768px) {
    .product_detals_container img{
        width: 100%;
        height: auto;
    }
}
@media only screen and (max-width: 488px) {
    .product_list_container{
       flex-direction: column;
    }
}