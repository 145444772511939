.call-container { grid-area: 1 / 1 ; }
.facebook-container { grid-area: 1 / 2 ; }
.instagram-container { grid-area: 1 / 3 ; }
.linkedIn-container { grid-area: 1 / 4 ; }
.whatsapp-container { grid-area: 1 / 5; }

.grid-container {
  display: none;  
  /* display: grid; */
  grid-auto-rows: 100px;
  background-color: #2196F3;
}

.grid-container > div {
  background-color: rgba(255, 255, 255, );
  text-align: center;
  padding: 15px 0;
  font-size: 20px;

}

.call-container{
    background-color: #51a245;
}
.facebook-container{
    background-color: #31487c;
}
.instagram-container{
    background-color: #000000;
}
.linkedIn-container{
    background-color: #125a95;
}
.whatsapp-container{
    background-color: #1baa52;
}
i{
    color: white;
    padding: 0.5rem;
}


/*************** media query ************/

/* Large devices (desktops, 992px and bellow) */
@media only screen and (max-width: 992px) {
    .grid-container { 
        display: grid;
      }
}

/* Medium devices (tablets, 768px and bellow) */
@media only screen and (max-width: 768px) {
    .grid-container > div {
        padding: 20px 0;
        /* font-size: 30px; */
      }
}

/* Small devices (landscape phones, 576px and bellow */
@media only screen and (max-width: 576px) {
    .grid-container {
        display: grid;
        grid-auto-rows: 70px;
        background-color: #2196F3;
    }
    .grid-container > div {
        padding: 10px 0;
        font-size: 15px;
      }
}