
.product-page-container {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.individual-product{
    border: 1px solid green;
    min-width: 18rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    margin-top: 1rem;
}

.product-button-text{
    text-decoration: none;
    color: white;
}
.product-button-text:hover{
    color: rgb(0, 0, 0);
}