.Header {
    position: relative;
    text-align: center;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centered>hr {
    height: 5px !important;
    /* color: white; */
    border-radius: 10px;
    margin: auto;
}

.address {
    width: 100%;
    background-color: #2482a9;
}

.location-information {
    display: flex;
    justify-content: center;
}

.google-map {
    width: 600px;
    height: 450px;
}

.contact-form {
    display: flex;
    justify-content: center;
}

/* medium device (tablet, 992px and bellow) */
@media only screen and (max-width: 992px) {
    .location-information {
        flex-direction: column;
    }

    .google-map {
        width: 100%;
    }
}

/* Small devices (landscape phones, less than 768px)  */
@media only screen and (max-width: 768px) {
    .google-map {
        width: 100%;
    }
}

/* X-Small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
    .google-map {
        width: 100%;
    }
}