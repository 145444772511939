.home-product-list-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.home-individual-product{
    border: 1px solid green;
    min-width: 18rem;
    /* padding: 2rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    margin-top: 1rem;
}

.home-view-more-button{
    margin-bottom: 1rem;
}
