.product-container {
    /* margin: 10 200; */
    margin-top: 1rem;
    border: 1px solid rgb(205, 10, 0);
}

.product-heading {
    display: flex;
    justify-content: center;
    /* color: red; */
}

.product-bullet-marks {
    padding: 1rem;
    font-size: 25px;
    display: flex;
    justify-content: space-around;
}




/* View Details */
/* Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 768px) {
    .product-bullet-marks {
        flex-wrap: wrap;
    }
}