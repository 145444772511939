.company-name{
    text-decoration: none;
}

.developers-name{
    margin-left: 1rem;
}
.quick-link{
    color: red;
}
.quick-link:hover{
    color: red !important;
}